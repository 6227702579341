import React from 'react'
import { Typography, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <React.Fragment>
            <Typography variant="h2">Strony nie odnaleziono. </Typography>
            <Typography variant="h3">Szukana strona nie istnieje lub nie masz uprawnień do wyświetlenia.</Typography>
            <Button variant="contained" color="primary" className="white-link" style={{marginTop: "20px"}} component={Link} to="/">Strona główna</Button>
        </React.Fragment>
    )
}

export default NotFound