
const fetchRefreshToken = async (ftoken, need_logout_url) => {
  let fdata = new FormData()
  fdata.set("token", ftoken)
  fdata.set("needLogout", need_logout_url)
  let dest_url = window.AddrConfig.Auth
  const response = await fetch(`${dest_url}/refresh`, {
      method: "POST",
      body: fdata
    }).then((response) => {
      if (response.status !== 200) {
        throw Error('status'+response.status)
      }
      else return response.json()
    })
      
  return response
  
}


export default fetchRefreshToken
