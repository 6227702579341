import { isExpired, decodeToken } from "react-jwt";
import fetchConfig from "./data/fetchConfig";
import fetchRefreshToken from "./data/fetchRefreshToken";
import Cookies from "js-cookie";
import { fetchLogin } from "./data";

const APIToken = "APIToken"
const RefreshToken = "APIToken-refresh"
const UserUrl = "UserUrl"
const TokenTimestamp = "Timestamp"
const LogoutUrl = "LogoutUrl"
const CookieKey = "session-key"

export default class SessionApi {
    static CHECK_STATE = "CHECK"
    static token_cache = null
    static user_url = null
    static api_token = null
    static login_token = null
    static login_in_progress = false
    static logout_url = null
    static login_error = null
    static logged_username = null
    static loginInProgress() {
        return this.login_in_progress
    }
    static getUserName() {
        return this.logged_username
    }
    static getLoginError() {
        return this.login_error
    }
    static checkLoginState() {
        if (this.user_url) return null
        let rkey = Cookies.get(CookieKey);
        if (!rkey) return null
        this.login_token = rkey
        Cookies.remove(CookieKey)
        return this.CHECK_STATE;
    }
    static getLogoutUrl() {
        return this.logout_url
    }
    static getLoginUrl(uname, accountSrc) {
        if (accountSrc === "local") return null
        let baseSignUrl = ""
        let ustate = accountSrc
        let client_id = ""
        let sparams = "response_type=id_token&scope=openid+profile"
        const hostport = window.location.origin;
        const redir_url = encodeURIComponent(hostport+"/login")
        if (accountSrc === 'google') {
            baseSignUrl = "https://accounts.google.com/o/oauth2/v2/auth"
            client_id = "130056685556-6fhigsoaug9vup9oqfhkhti42hit7h1v.apps.googleusercontent.com"
            sparams = "response_type=id_token&scope=openid+email"
        }
        else if (accountSrc === 'euros' || accountSrc === 'microsoft') {
            let domain = "eurosene.onmicrosoft.com"
            if (accountSrc !== "euros") domain = "common"
            baseSignUrl = `https://login.microsoftonline.com/${domain}/oauth2/v2.0/authorize`
            client_id = "759e9a35-8d68-4908-a09d-f4b316d2fccf"
            sparams += "&response_mode=form_post" 
        }
        let reqid = Math.floor(Math.random() * 1000000000)
        let user_hint = uname ? `&login_hint=${uname}` : ""
        return `${baseSignUrl}?client_id=${client_id}&${sparams}&state=${ustate}&nonce=${reqid}&redirect_uri=${redir_url}${user_hint}`
    }
    static getUserUrl() {
        if (this.user_url) return this.user_url
        if (window.location.pathname === '/drop') {
            this.doLogout();
            return null;
        }
        let ftoken = sessionStorage.getItem(RefreshToken)
        if (ftoken) {
            this.logout_url = sessionStorage.getItem(LogoutUrl)
            let atoken = sessionStorage.getItem(APIToken)
            let uurl = sessionStorage.getItem(UserUrl)
            if (atoken && uurl) {
                this.api_token = atoken
                this.token_cache = decodeToken(this.api_token)
                this.user_url = uurl
                return uurl
            }
        }
        return null
    }
    static getUserPerm() {
        if (this.token_cache && this.token_cache.perms) {
            return this.token_cache.perms
        }
        return {}
    }
    static doLogout() {
        sessionStorage.removeItem(RefreshToken)
        sessionStorage.removeItem(APIToken)
        sessionStorage.removeItem(UserUrl)
        sessionStorage.removeItem(TokenTimestamp)
        sessionStorage.removeItem(LogoutUrl)
        this.token_cache = null
        this.api_token = null
        this.user_url = null
    }
    static async makeLogin() {
        this.login_in_progress = true
        let timeStamp = Date.now()
        let tjson = await fetchRefreshToken(this.login_token, true)
        if (!tjson.logoutUrl) {
            this.login_in_progress = false
            if (!('error' in tjson)) 
                throw new Error("Problem z pobraniem danych z serwera 1")
            this.login_error = tjson.error
            return { error: this.login_error }
        }
        this.logout_url = tjson.logoutUrl
        if (!tjson.token) {
            this.login_in_progress = false
            if (!('error' in tjson)) 
                throw new Error("Problem z pobraniem danych z serwera 2")
            this.login_error = tjson.error
            return { error: this.login_error, logoutUrl: tjson.logoutUrl }
        }
        sessionStorage.setItem(TokenTimestamp, timeStamp)
        sessionStorage.setItem(RefreshToken, this.login_token)
        sessionStorage.setItem(LogoutUrl, tjson.logoutUrl)
        this.api_token = tjson.token
        this.logout_url = tjson.logoutUrl
        sessionStorage.setItem(APIToken,this.api_token)
        this.token_cache = decodeToken(this.api_token)
        tjson = await fetchConfig(this.api_token)
        if (!tjson.userUrl) {
            this.login_in_progress = false
            if (!('error' in tjson)) 
                throw new Error("Problem z pobraniem danych z serwera 3")
            this.login_error = tjson.error;
            return { error: this.login_error, logoutUrl: this.logout_url, userUrl: null};
        }
        this.user_url = tjson.userUrl
        sessionStorage.setItem(UserUrl, this.user_url)
        this.login_in_progress = false
        return { logoutUrl: this.logout_url, userUrl: tjson.userUrl }
    }
    static async doLogin(user,passwd) {
        let tjson
        try {
            tjson = await fetchLogin(user,passwd)
        } catch (err) {
            if (err.message === "status401") {
                return { 'error': "Niepoprawny login lub hasło", 'logoutUrl': 'local'}
            }
            else {
                throw err
            }
        }
        this.login_token = tjson.token
        return await this.makeLogin()
    }
    static clearProgress() {
        this.login_in_progress = false
    }
    static async getApiToken() {
        if(isExpired(this.api_token)) {
            let timeStamp = new Date()
            timeStamp = Date.now()
            let tjson = await fetchRefreshToken(sessionStorage.getItem(RefreshToken), false)
            this.api_token = tjson.token
            this.token_cache = decodeToken(this.api_token)
            sessionStorage.setItem(TokenTimestamp, timeStamp)
        }
        return this.api_token
    }
}