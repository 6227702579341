
const fetchDevice = async(token) => {
    let dest_url = window.AddrConfig.Cfg
    const res = await fetch(`${dest_url}/userconf`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`
            }
    }).then(response => response.json())

    return res
    
}

export default fetchDevice