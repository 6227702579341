import React, { useState, useEffect } from 'react'
import {
    Redirect
} from 'react-router-dom'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import SessionApi from '../../SessionApi'


const Login = ({ appState, setAppState }) => {
    const [loginState, setLoginState] = useState(SessionApi.checkLoginState())
    const [showMore,setShowMore] = useState(false)
    const handleLoginResponse = (r) => {
        if (!r) return
        if ('error' in r) {
            if ('userUrl' in r) {
                setLoginState(["Brak uprawnień do tej aplikacji",r.error])
            }
            else if ('logoutUrl' in r) {
                setLoginState(["Konto nieznane w systemie",r.error])
            }
            else {
                setLoginState(["Problem z systemem autoryzacji",r.error])
            }
            if ('logoutUrl' in r) setAppState({ type: 'logoutUrl', payload: r.logoutUrl})
        }
        else setAppState({type: "login", payload: r})
    }
    const handleSubmit = (ev) => {
        if (ev) {
            let uname = ev.target[0].value
            let acctype = ev.nativeEvent.submitter.name
            let loginurl = SessionApi.getLoginUrl(uname,acctype)
            if (loginurl) window.location.href = loginurl
            else {
                let passwd = ev.target[1].value
                SessionApi.doLogin(uname,passwd).catch((err) => {
                    SessionApi.clearProgress()
                    setLoginState(['Problem z pobieraniem danych z serwera',err.message])
                }).then((r) => {handleLoginResponse(r)})
            }
        }
        ev.preventDefault()
    }
    useEffect(() => {
        if (loginState === SessionApi.CHECK_STATE && !SessionApi.loginInProgress()) {
            SessionApi.makeLogin().catch((err) => {
                SessionApi.clearProgress()
                setLoginState(['Problem z pobieraniem danych z serwera',err.message])
            }).then((r) => {handleLoginResponse(r)})
        }
        else if (appState.toLogin) {
            setLoginState(null)
            setAppState({type: 'return', payload: false})
        }
    })
    const bstyle = { 'text-transform': "none"}
    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >{appState.userUrl ?  <Redirect to="/" /> : null}
            {loginState ?
                ( loginState === SessionApi.CHECK_STATE ? <p>Sprawdzanie uprawnień...</p> : 
                <p>Nie można pobrać danych o instalacjach<br/>
                Problem: {loginState[0]}<br/>Komunikat z serwera: {loginState[1]}</p>
                ) :
                   
                        <form onSubmit={handleSubmit}>
                            <Grid container direction="row" >
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h2" className="text-center">Logowanie</Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" >
                                <Grid item xs={12} md={6}>
                                    <TextField name="login" label="e-mail" autoComplete="username"/>
                                </Grid>
                            </Grid>
                            { showMore ? null : 
                            <Grid container direction="row" >
                                <Grid item xs={12} md={6}>
                                    <TextField name="passwd" label="hasło" type="password" autoComplete="password"/>
                                </Grid>
                            </Grid>
                            }
                            { showMore ? null : 
                            <Grid container direction="row" >
                                <Grid item xs={12} md={6}><br />
                                    <Button
                                        variant="contained" color="primary" type="submit" name="local"
                                        >Zaloguj</Button>
                                </Grid>
                            </Grid>
                            }
                            <Grid container direction="row" >
                                <Grid item xs={12} md={6}><br />
                                    <Button
                                        variant="outlined" style={bstyle}
                                        onClick={() => {setShowMore(!showMore)}}
                                        >{!showMore ? "Więcej opcji" : "Mniej opcji"}</Button>
                                </Grid>
                            </Grid>
                            { !showMore ? null : 
                            <Grid container direction="column" >
                                <Grid item xs={12} md={6}><br />
                                    <Button
                                        variant="contained" type="submit" name="euros"
                                        >Autoryzuj kontem Microsoft</Button>
                                </Grid>
                            </Grid>
                            }
                        </form>
            }
            </Grid>
        </React.Fragment>
    )
}

export default Login