import { createTheme } from '@material-ui/core/styles'
import { plPL } from '@material-ui/core/locale'

const theme = createTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiContainer: {
            root: {
                marginTop: '0 !important',
            },
        },
        MuiFormControl: {
            root: {
                margin: '0 auto 0px',
                '& .PrivateSwitchBase-': {
                    padding: 0,
                },
                width: '100%'
            },
        },
        MuiFormLabel: {
            root: {
                marginBottom: '10px',
                '@media (max-width:600px)': {
                    fontSize: '12px',
                  },
            }
        },
        MuiInput: {
            underline: {
                '&:hover': {
                    '&:not(.Mui-disabled)': {
                        '&:before': {
                            borderBottom: "2px solid #005492",
                        },
                    },
                },
            },
            root: {
                marginBottom: "15px",
                padding: "0 5px",
            '@media (max-width:600px)': {
                fontSize: '14px',
              },
            },
        },
        MuiInputLabel: {
            root: {
                '@media (max-width:600px)': {
                    fontSize: '14px',
                  },
            },
        },
        MuiTableHead: {
            root: {
                backgroundColor: '#f5f5f5',
                color: "#ffffff",
                textTransform: "uppercase",
            }
        },
        MuiTableCell: {
            root: {
                fontWeight: "normal",
                fontSize: '14px',
                verticalAlign: 'top',
            },
            body: {
                color: "rgba(0, 0, 0, 1)",
            }
        },
        MuiInputBase: {
            input: {
                textAlign: 'center'
            }
        },
        MuiGrid: {
            item: {
                padding: '0 15px',
                position: 'relative'
            }
        },
        MuiMenu: {
            list: {
                textAlign: 'center'
            }
        },
        
       
    },
    typography: {
        h1: {
            fontSize: '36px',
            margin: '20px auto 0',
            '@media (max-width:960px)': {
                fontSize: '30px',
              },
            '@media (max-width:600px)': {
                fontSize: '26px',
              },
        },
        h2: {
            fontSize: '26px',
            margin: '20px auto',
            '@media (max-width:960px)': {
                fontSize: '22px',
              },
            '@media (max-width:600px)': {
                fontSize: '18px',
                margin: '20px auto 10px',
              },
        },
        h3: {
            fontSize: '18px',
            margin: '20px auto 0',
            '@media (max-width:960px)': {
                fontSize: '16px',
              },
            '@media (max-width:600px)': {
                fontSize: '14px',
              },
        },
        body2: {
            fontSize: '12px',
            margin: '0 auto',
            fontWeight: 'lighter'
,            '@media (max-width:600px)': {
                fontSize: '10px',
              },
        },
        body1: {
            fontSize: '14px',
        },
    },
    palette: {
        primary: {
            main: '#005492',
        },
    },
    margin: {
        marginLarge: '50px auto',
        marginMedium: '30px auto',
        marginSmall: '15px auto',
    },
    input: {
        marginBottom: '20px',
    },
}, plPL)



export default theme