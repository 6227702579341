
const fetchDevice = async (user_url, token_src, keyM) => {

    let newToken = await token_src
    const res = await fetch(`${user_url}/sockconf?dev=${keyM}`, {
        method: "GET",
        headers:{
          "Authorization": `Bearer ${newToken}`
        }
    }).then(response => response.json())
    
    return res
    
}

  export default fetchDevice