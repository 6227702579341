
const fetchConfigDevices = async(userUrl, token_src) => {
    const newToken = await token_src
    const res = await fetch(`${userUrl}/devices`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${newToken}`
            }
    }).then(response => {
        if (response.ok) {
            return response.json()
        } else {
            throw new Error(response.status)
        }
    })

    return res
    
}


export default fetchConfigDevices