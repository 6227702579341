import React, { useReducer, useEffect } from 'react'
import {
    Redirect
} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Grid, TextField, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core'
import ListItem from './ListItem'

import fetchConfigDevices from '../../data/fetchConfigDevices'
import SessionApi from '../../SessionApi'


const deviceListReducer = (state, action) => {
    switch (action.type) {
        case "DEVICE_LIST":
            return {
                ...state,
                devices: action.payload
            }

        case "LOAD":
            return {
                ...state,
                load: action.payload
            }

        case "FILTER":
            return {
                ...state,
                filter: action.payload
            }

        default:
            return state
    }

}

const filteredInstallationList = (itemList, filter) => {
    let devices
    let devicesNewList

    if (filter.searchKey === "all") {
        devicesNewList = itemList.map((item, index) => <ListItem key={item.key} item={item} index={index} />)
    } else if (filter.searchKey === "deviceName") {
        devices = itemList.filter(item => item.deviceName !== null && item.deviceName.includes(filter.searchValue) === true)
    } else if (filter.searchKey === "location") {
        devices = itemList.filter(item => ((item.location.toUpperCase().includes(filter.searchValue)) === true))
    }
    if (devices)
        devicesNewList = devices.map((item, index) => (
            <ListItem key={item.key} index={index} item={item} />
        ))
    return devicesNewList
}

const DeviceList = ({ appState }) => {
    const [deviceList, setDeviceList] = useReducer(deviceListReducer, { devices: null, load: false, filter: { searchKey: "all", searchValue: "" } })

    useEffect(() => {
        if (deviceList.load !== true && appState.userUrl) {
            setDeviceList({ type: "LOAD", payload: true })
            fetchConfigDevices(appState.userUrl, SessionApi.getApiToken())
                .then(response => { 
                    setDeviceList({ type: "DEVICE_LIST", payload: response.data }) 
                })
                .catch(error => {
                    console.log(error)
                })
        }
        return function cleanup() {
        }
    })

    const filterInstallationList = (e) => {
        if (e.target.value === "")
            setDeviceList({ type: "FILTER", payload: { searchKey: "all", searchValue: "" } })
        else
            setDeviceList({ type: "FILTER", payload: { searchKey: e.target.name, searchValue: e.target.value.toUpperCase() } })
    }

    let filteredList
    if (deviceList.devices && deviceList.filter)
        filteredList = filteredInstallationList(deviceList.devices, deviceList.filter)

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid container direction="row" >
                    <Grid item xs={12}>
                        <Typography variant="h2">Lista instalacji</Typography>
                    </Grid>
                </Grid>

                {!appState.userUrl ? <Redirect to="/login" /> : null}
                {deviceList.devices ? (
                    <React.Fragment>
                        <Grid container direction="row" >
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography component="div">
                                                    <Box fontWeight="fontWeightBold">
                                                        Lp
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <TextField label="Nazwa" name="deviceName" type="text" onChange={(e) => filterInstallationList(e)}
                                                    value={deviceList.filter.searchKey === "deviceName" ? deviceList.filter.searchValue : ""} />
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="div">
                                                    <Box fontWeight="fontWeightBold">
                                                    <TextField label="Lokalizacja" name="location" type="text" onChange={(e) => filterInstallationList(e)}
                                                    value={deviceList.filter.searchKey === "location" ? deviceList.filter.searchValue : ""} />
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <p></p>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredList.map((row, index) => (
                                            <React.Fragment key={index}>{row}</React.Fragment>
                                        ))}
                                    </TableBody>
                                    
                                </Table>
                            </TableContainer>
                        </Grid>
                    </React.Fragment>)
                    : <CircularProgress />}
            </Grid>
        </React.Fragment>
    )
}

export default DeviceList