import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { VncDisplay } from '../../components'
import fetchDevice from '../../data/fetchDevice'
import SessionApi from '../../SessionApi'
import {enable} from 'debug'

enable("*");

const Device = ({ appState, setState }) => {
    let { devId } = useParams()
    let [connectionUrl, setName] = useState(null)

    const onConnected = () => {
    }
    useEffect(() => {
        let mounted = true
        if (!appState.inVnc) setState({type: 'inVnc', payload: true})
        if (devId && !connectionUrl) {
            fetchDevice(appState.userUrl, SessionApi.getApiToken(), devId)
                .then(response => {
                    if (mounted) {
                        
                        setName(response.connectionUrl)
                    }
                })
        }
        return function cleanup() {
            mounted = false
        }
    })

    return (
        <React.Fragment>
            {!appState.userUrl ? <Redirect to="/login" /> : null}
            {!connectionUrl ? (<p>Connecting...</p>) :
            <VncDisplay url={connectionUrl} onConnected={onConnected}/> }
        </React.Fragment>
    )
}

export default Device