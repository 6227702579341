import React from 'react'
import {TableCell, TableRow, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

const ListItem = ({ item, index }) => {
    return (
        <TableRow>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{item.deviceName}</TableCell>
            <TableCell>{item.location}</TableCell>
            {item.hasVnc ? (<TableCell><Link to={`/vnc/${item.key}`}>
            <Button variant="contained" color="primary">VNC</Button></Link></TableCell>) : null}
        </TableRow>
    )
}

export default ListItem