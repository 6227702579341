import React, { useReducer } from 'react'
import './App.css'
import { Container } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { mainStyleTheme } from './theme'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { Header } from './components'
import { Login, DeviceVnc, DevicesList, NotFound } from './pages'
import SessionApi from './SessionApi'


const baseStateReducer = (state, action) => {
  if (action.type === "device") {
    return { ...state, device: action.payload }
  } else if (action.type === "login") {
    return { ...state, userUrl: action.payload.userUrl, logoutUrl: action.payload.logoutUrl }
  } else if (action.type === "logout") {
    SessionApi.doLogout()
    return { ...state, perm: {}, userUrl: null, logoutUrl: null }
  } else if (action.type === "perm") {
    return { ...state, perm: action.payload }
  } else if (action.type === "userUrl") {
    return { ...state, userUrl: action.payload }
  } else if (action.type === 'inVnc') {
    return { ...state, inVnc: action.payload }
  } else if (action.type === 'logoutUrl') {
    return { ...state, logoutUrl: action.payload }
  } else if (action.type === 'return') {
    return { ...state, toLogin : action.payload }
  }
}

function App() {
  const uUrl = SessionApi.getUserUrl()
  const [fullAppState, setAppState] = useReducer(baseStateReducer, {
    userUrl: uUrl, 
    logoutUrl: SessionApi.getLogoutUrl(),
    perm: SessionApi.getUserPerm(), 
    inVnc: false 
  })

  return (
    <Router className="App">
      <ThemeProvider theme={mainStyleTheme}>
        <Header setState={setAppState} appState={fullAppState} />
        <Container style={{ marginTop: "30px" }} maxWidth="lg">
          <Switch>
            <Route exact path="/" >
              <DevicesList appState={fullAppState} /> 
            </Route>
            <Route exact path="/logout" component={() => {
              let url = SessionApi.getLogoutUrl();
              if (url) window.location.href = url
              else window.location.href = window.location.origin
              return null;
            }}/>
            <Route exact path="/drop">
              <Redirect to="/login"/>
            </Route>
            <Route exact path="/login"><Login appState={fullAppState} setAppState={setAppState} /></Route>
            <Route exact path="/vnc/:devId" >
              <DeviceVnc appState={fullAppState} setState={setAppState} />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Container>
      </ThemeProvider>
    </Router>
  );
}

export default App;
