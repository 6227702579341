import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { mainStyleClasses } from '../../theme'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const Navigation = ({ setState, appState }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const classes = mainStyleClasses()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    
    const handleLogout = () => {
        setState({ type: "logout" })
    }

    const handleReturn = () => {
        setState({ type: "return", payload: true})
    }

    return (
        <React.Fragment>
            {appState.logoutUrl ? <Button className={`${classes.floatRight} ${classes.buttonMargin}`} variant="contained" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                Menu
            </Button>: null}
            <Menu
                id="menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {appState.logoutUrl !== "local" ?
                <MenuItem onClick={() => {handleLogout(); handleClose()}} component={Link} to="/logout">Wyloguj</MenuItem>
                :
                appState.userUrl ? null :
                <MenuItem onClick={() => {handleReturn(); handleClose()}} component={Link} to="/login">Powrót</MenuItem>
                }
                {appState.userUrl ? 
                <MenuItem onClick={() => {handleLogout(); handleClose()}} component={Link} to="/login">Zakończ</MenuItem>
                : null }
                {appState.inVnc ?
                <MenuItem onClick={() => {setState({type:"inVnc", payload: false}); handleClose()}} component={Link} to="/">Zamknij</MenuItem>
                : null }
            </Menu>
        </React.Fragment>
    )
}

export default Navigation