import React from 'react';
import logo from '../../img/LogoEuros.svg'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import { mainStyleClasses } from '../../theme'
import { Navigation } from '../'

const Header = ({appState, setState}) => {
    const classes = mainStyleClasses()
    return (
        <AppBar position="static">
            <Grid container>
                <Grid item xs={6}>
                    <img className={classes.logo} src={logo} alt="Euros Energy"
                        title="Euros Energy"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Navigation setState={setState} appState={appState} />
                </Grid>
            </Grid>
        </AppBar>
    )
}

export default Header;