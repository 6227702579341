
const fetchLogin = async (user,passwd) => {
    let fdata = new FormData()
    fdata.set("user", user)
    fdata.set("passwd",passwd)
    let dest_url = window.AddrConfig.Auth
    const response = await fetch(`${dest_url}/login`, {
        method: "POST",
        body: fdata
      }).then((response) => {
        if (response.status !== 200) {
          throw Error('status'+response.status)
        }
        else return response.json()
      })
        
    return response
    
  }
  
  
  export default fetchLogin
  